import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

export default function ServMore() {
  const { t, i18n } = useTranslation();
 const isArabic = i18n.language === 'ar';
    
    return <div dir={isArabic?'rtl':''}>
        <div class="container-xxl py-5">
            <div class="container py-5">
                <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h1 class="mb-5">LCL / FCL / Break Bulk / Reefers </h1>
                </div>
                <div class="row g-4">
                    <div class="col-md-6  wow fadeInUp" data-wow-delay="0.3s">
                        <div class="price-item">
                            <div class="border-bottom p-4 mb-4">
                                <h5 class="text-danger mb-1">{t('contain1')}</h5>

                            </div>
                            <div class="p-4 pt-0">
                                <p>
                                {t('contain2')}
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-6  wow fadeInUp" data-wow-delay="0.3s">
                        <div class="price-item">
                            <div class="border-bottom p-4 mb-4">
                                <h5 class="text-danger mb-1">{t('contain3')}</h5>

                            </div>
                            <div class="p-4 pt-0">
                                <p>

                                {t('contain4')}

                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-6  wow fadeInUp" data-wow-delay="0.3s">
                        <div class="price-item">
                            <div class="border-bottom p-4 mb-4">
                                <h5 class="text-danger mb-1"> {t('contain5')}</h5>

                            </div>
                            <div class="p-4 pt-0">
                                <p>

                                {t('contain6')}
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-6  wow fadeInUp" data-wow-delay="0.3s">
                        <div class="price-item">
                            <div class="border-bottom p-4 mb-4">
                                <h5 class="text-danger mb-1">{t('contain7')}</h5>

                            </div>
                            <div class="p-4 pt-0">
                                <p>
                                {t('contain8')}
                                </p>

                            </div>
                        </div>
                    </div>



                </div>
                
                <div className="text-center my-5">
                <h2>{t('contain9')}</h2>
                <Link to="/contact" className="btn btn-danger my-2 py-3 px-5">{t('btn2')}</Link>
                </div>

            </div>
        </div>
    </div>
}
