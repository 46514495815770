import React, { useEffect } from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useTranslation } from 'react-i18next'
export default function Footer() {

  const { t, i18n } = useTranslation();
 const isArabic = i18n.language === 'ar';
useEffect(() => {
  Aos.init({
    duration:2000
  })
}, [])

  return <div >
  <div className="container-fluid bg-dark text-light footer pt-5 wow " style={{marginTop: '6rem'}}>
<div className="container py-5">
    <div className="row g-5">
        <div className="col-lg-3 col-md-6" data-aos='fade-up' > 
            <h4 className="text-light mb-4">{t('add')}</h4>
            <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>{t('address')}</p>
            <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>  +2034238500 </p>
            <p className="mb-2"><i className="fa fa-phone-alt me-3"></i> +201070757552 </p>
            <p className="mb-2"><i className="fa fa-envelope me-3"></i>info@n-c-logistics.com</p>
            <div className="d-flex pt-2">
                <a className="btn btn-outline-light btn-social" target='_blank' href="https://www.facebook.com/profile.php?id=61552945193682&mibextid=ZbWKwL">
                <i className="fab fa-facebook-f"></i></a>
                <a className="btn btn-outline-light btn-social" href="https://www.linkedin.com/company/n-c-logistics/">
                    <i className="fab fa-linkedin-in"></i></a>
            </div>
        </div>
        <div className="col-lg-3 col-md-6" data-aos='fade-up'>
            <h4 className="text-light mb-4">{t('resources')}</h4>
            <a target='blank' className="btn btn-link" href="https://www.wto.org/">WTO</a>
            <a target='blank' className="btn btn-link" href="http://www.wcoomd.org/">WCO</a>
            <a target='blank' className="btn btn-link" href="http://www.customs.gov.eg/">Egyptian Customs</a>
            <a target='blank' className="btn btn-link" href="http://www.tra.gov.eg/">NTRA</a>
            <a target='blank' className="btn btn-link" href="http://fiata.com/home.html">FIATA</a>
            <a target='blank' className="btn btn-link" href="http://www.eiffa.com/">EIFFA</a>
            <a target='blank' className="btn btn-link" href="http://www.goeic.gov.eg/">G.O.E.I.Cs</a>
        </div>
        <div className="col-lg-3 col-md-6" data-aos='fade-up'>
            <h4 className="text-light mb-4">{t('links2')}</h4>
            <Link className="btn btn-link" to="about">{t('about')}</Link>
            <Link className="btn btn-link" to="contact">{t('contact')}</Link>
            <Link className="btn btn-link" to="services">{t('services')}</Link>
            <Link className="btn btn-link" to="features">{t('features')}</Link>
            <Link className="btn btn-link" to="quality">{t('quality')}</Link>
            <Link className="btn btn-link" to="support">{t('featt10')}</Link>
        </div>
        <div className="col-lg-3 col-md-6" data-aos='fade-up'>
            <h4 className="text-light mb-4">{t('news')}</h4>
            <p>{t('news2')}</p>
            <div className="position-relative mx-auto" style={{maxWidth: "400px"}}>
                <input className="form-control border-0 w-100 py-3 ps-4 pe-5" type="text" placeholder={t('email')}/>
                <button type="button" className="btn btn-danger py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
            </div>
        </div>
    </div>
</div>
<div className="container">
    <div className="copyright">
        <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                &copy; N.C LOGISTICS All Right Reserved.
            </div>
           
        </div>
    </div>
</div>
</div>
  </div>
}
