import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

export default function Features() {
    const { t, i18n } = useTranslation();
     const isArabic = i18n.language === 'ar';

    return <div dir={isArabic?'rtl':''}>
        <div className="container-xxl py-5">
            <div className="container py-5">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h6 className="text-muted mt-5 text-uppercase">{t('feat0')}</h6>
                    <h1 className="mb-5">{t('featt0')}</h1>
                </div>
                <div className="row g-4">
                    <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="price-item">
                            <div className="border-bottom p-4 mb-4">
                                <h5 className="text-danger mb-1">{t('feat2')}</h5>

                            </div>
                            <div className="p-4 pt-0">
                                <p>{t('featt')}
                                </p>

                                <Link className="btn-slide mt-2" to="/contact"><i className="fa fa-arrow-right"></i><span >{t('order')}</span></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="price-item">
                            <div className="border-bottom p-4 mb-4">
                                <h5 className="text-danger mb-1"> {t('feat4')}</h5>

                            </div>
                            <div className="p-4 pt-0">
                                <p>{t('featt1')}
                                </p>

                                <Link className="btn-slide mt-2" to="/contact"><i className="fa fa-arrow-right"></i><span >{t('order')}</span></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="price-item">
                            <div className="border-bottom p-4 mb-4">
                                <h5 className="text-danger mb-1">{t('featt2')}</h5>

                            </div>
                            <div className="p-4 pt-0">
                                <p>{t('featt3')}
                                </p>

                                <Link className="btn-slide mt-2" to="/contact"><i className="fa fa-arrow-right"></i><span >{t('order')}</span></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="price-item">
                            <div className="border-bottom p-4 mb-4">
                                <h5 className="text-danger mb-1">{t('featt4')}</h5>

                            </div>
                            <div className="p-4 pt-0">
                                <p>{t('featt5')}
                                </p>

                                <Link className="btn-slide mt-2" to="/contact"><i className="fa fa-arrow-right"></i><span >{t('order')}</span></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="price-item">
                            <div className="border-bottom p-4 mb-4">
                                <h5 className="text-danger mb-1">{t('featt6')}</h5>

                            </div>
                            <div className="p-4 pt-0">
                                <p>{t('featt7')}
                                </p>

                                <Link className="btn-slide mt-2" to="/contact"><i className="fa fa-arrow-right"></i><span >{t('order')}</span></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="price-item">
                            <div className="border-bottom p-4 mb-4">
                                <h5 className="text-danger mb-1">{t('featt8')}</h5>

                            </div>
                            <div className="p-4 pt-0">
                                <p>{t('featt9')}
                                </p>

                                <Link className="btn-slide mt-2" to="/contact"><i className="fa fa-arrow-right"></i><span >{t('order')}</span></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="price-item">
                            <div className="border-bottom p-4 mb-4">
                                <h5 className="text-danger mb-1"> {t('featt10')}</h5>

                            </div>
                            <div className="p-4 pt-0">
                                <p>{t('featt11')}
                                </p>

                                <Link className="btn-slide mt-2" to="/contact"><i className="fa fa-arrow-right"></i><span >{t('order')}</span></Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
}
