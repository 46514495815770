import React, { useEffect } from 'react'
import img1 from '../../img/about.jpg'
import img2 from '../../img/feature.jpg'
import { Link } from 'react-router-dom'
import Facts from '../Home/Facts.jsx'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useTranslation } from 'react-i18next'
export default function About() {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        Aos.init({
            duration: 2000
        })
    }, [])
    const isArabic = i18n.language === 'ar';
  
    return <div  dir={isArabic?'rtl':''}>

        <div className="container overflow-hidden py-5 px-lg-0">
            <div className="my-5 text-light">.</div>

            <div className="text-center my-5">
                <h2 className='text-danger' data-aos='fade-up'>{t('title2')}</h2>
                <p className=' h4 text-muted w-75 mx-auto' data-aos='fade-up'>
                    {t('cont2')}
                </p>

            </div>
            <div className="container about py-5 px-lg-0">
                <div className="row g-5 mx-lg-0">
                    <div className="col-lg-6 ps-lg-0 wow " data-aos='fade-up' style={{ minHeight: '400px' }}>
                        <div className="position-relative h-100 imagg">
                            <img className="position-absolute img-fluid w-100 h-100" src={img1} style={{ objectFit: 'cover' }} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 about-text wow " data-aos='fade-up'>
                        <h6 className=" text-uppercase mb-3">{t('about0')}</h6>
                        <h1 className="mb-5 text-danger">{t('about1')}</h1>
                        <p className="mb-5">{t('about2')}</p>
                        <div className="row g-4 mb-5">
                            <div className="col-sm-6 wow " data-aos='fade-right'>
                                <i className="fa fa-globe fa-3x text-danger mb-3"></i>
                                <h5>{t('about3')}</h5>
                                <p className="m-0">{t('about4')}</p>
                            </div>
                            <div className="col-sm-6 wow " data-aos='fade-left'>
                                <i className="fa fa-shipping-fast fa-3x text-danger mb-3"></i>
                                <h5>{t('about5')}</h5>
                                <p className="m-0">{t('about6')}</p>
                            </div>
                        </div>
                        <Link data-aos='fade-up' to="/freight" className="btn btn-danger py-3 px-5">{t('about7')}</Link>
                    </div>
                </div>
            </div>
        </div>

        <Facts />

        <div className="container-fluid overflow-hidden py-5 px-lg-0">
            <div className="container feature py-5 px-lg-0">
                <div className="row g-5 mx-lg-0">
                    <div className="col-lg-6 feature-text wow " data-aos='fade-up'>
                        <h6 className="text-lon text-uppercase mb-3">{t('feat0')}</h6>
                        <h1 className="mb-5 text-danger">{t('feat1')}</h1>
                        <div className="d-flex mb-5 wow " >
                            <i className="fa fa-globe text-danger fa-3x flex-shrink-0"></i>
                            <div className="ms-4" data-aos='fade-up'>
                                <h5>{t('feat2')}</h5>
                                <p className="mb-0">{t('feat3')}.</p>
                            </div>
                        </div>
                        <div className="d-flex mb-5 wow " >
                            <i className="fa fa-shipping-fast text-danger fa-3x flex-shrink-0"></i>
                            <div className="ms-4" data-aos='fade-up'>
                                <h5>{t('feat4')}</h5>
                                <p className="mb-0">{t('feat5')}</p>
                            </div>
                        </div>
                        <div className="d-flex mb-0 wow " data-aos='fade-up'>
                            <Link to="/features" className="btn btn-danger py-3 px-5">{t('btn1')}</Link>

                        </div>
                    </div>
                    <div className="col-lg-6 pe-lg-0 wow " data-aos='fade-up' style={{ minHeight: '400px' }}>
                        <div className="position-relative h-100 imagg">
                            <img className="position-absolute img-fluid w-100 h-100" src={img2} style={{ objectFit: 'cover' }} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        



    </div>
}
