import React from 'react'
import cust from '../../images/plane.jpg'
import cust2 from '../../images/WhatsApp Image 2023-10-25 at 15.50.41_c4137f5f.jpg'
import cust3 from '../../images/WhatsApp Image 2023-10-25 at 15.50.41_cd3d6010.jpg'
import cust4 from '../../images/WhatsApp Image 2023-10-25 at 15.50.41_5cc527f0.jpg'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
export default function Custom() {
    const { t, i18n } = useTranslation();
 const isArabic = i18n.language === 'ar';

    return <div dir={isArabic?'rtl':''}>
        <div className="container-xxl py-5">
            <div className=" my-5">
            <div className="my-5 text-light">.</div>

                <h2 className='text-danger h1 my-5'>{t('servname2')}</h2>
                <div className='row'>
                    <div className="col-md-3 p-2 imagg">
                        <img src={cust} alt="" className='w-100' />
                    </div>
                    <div className="col-md-3 p-2 imagg">
                        <img src={cust2} alt="" className='w-100' />
                    </div>
                    <div className="col-md-3 p-2 imagg">
                        <img src={cust3} alt="" className='w-100' />
                    </div>
                    <div className="col-md-3 p-2 imagg">
                        <img src={cust4} alt="" className='w-100' />
                    </div>


                </div>


            </div>
            <div className="container py-5">
                <div className='mb-5'>
                    <h2 className='text-danger'>{t('servname2')} </h2>
                    <h5 className='text-muted'> {t('cust1')} </h5>
                </div>
                <ul className='mb-5'>
                    <li className="my-2">
                        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>{t('cust2')}</h5>
                    </li>
                    <li className="my-2">
                        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>{t('cust3')}</h5>
                    </li>
                    <li className="my-2">
                        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>{t('cust4')}  </h5>
                    </li>
                    <li className="my-2">
                        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>{t('cust5')}</h5>
                    </li>
                </ul>
                <hr />
                <hr />
                <div className='my-5'>
                    <i className="me-3  text-success  fa-regular fa-circle-check"></i>
                    <h5 className='text-muted d-inline'>{t('cust6')}
                    </h5>
                </div>
                <ul>

                    <li className="my-4">
                        <h5 > 1-{t('cust7')}</h5>
                    </li>

                    <li className="my-4">
                        <h5 > 2- {t('cust8')} </h5>
                    </li>

                    <li className="my-4">
                        <h5 > 3-{t('cust9')}</h5>
                    </li>

                    <li className="my-4">
                        <h5 > 4- {t('cust10')}</h5>
                    </li>

                    <li className="my-4">
                        <h5 > 5- {t('cust11')}</h5>
                    </li>

                </ul>

                <div className='my-5'>
                    <i className="me-3  text-success  fa-regular fa-circle-check"></i>
                    <h5 className='text-muted d-inline'>
                    {t('cust12')}
                    </h5>
                    <hr />
                    <h5 className='text-muted'>  {t('cust13')}
                    </h5>
                </div>
                <hr />
                <hr />
                <Link to="/contact" className="btn btn-danger py-3 px-5"> {t('contact')}</Link>

            </div>
        </div>

    </div>
}
