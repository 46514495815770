import React, { useEffect } from 'react'
import mem from '../../images/members.PNG'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useTranslation } from 'react-i18next';

export default function Member() {
  const { t, i18n } = useTranslation();


useEffect(() => {
  Aos.init({
    duration:2000
  })
  
}, [])
    return <>
    <div className="container-xxl py-5">
    <div className="container py-5">
      <div className="text-center wow " data-aos='fade-up'>
        <h6 className="text-secondary text-uppercase">  {t('trust')}</h6>
        <h1 className="mb-5 text-danger">  {t('member')}</h1>
      </div>
      <div className="row g-4" data-aos='fade-up'>
        <img src={mem} alt="" />
      </div>
    </div>
  </div>
</>
}
