import React, { useEffect } from 'react'
import serv1 from '../../img/service-1.jpg'
import serv2 from '../../img/service-2.jpg'
import serv6 from '../../img/service-6.jpg'
import { Link } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useTranslation } from 'react-i18next'

export default function Services() {
  const { t, i18n } = useTranslation();
 const isArabic = i18n.language === 'ar';

  useEffect(() => {
    Aos.init({
      duration:2000
    })
    
  }, [])
  return <div dir={isArabic?'rtl':''}>
    <div className="container-xxl py-5">
      <div className="container py-5">
        <div className="text-center wow " data-aos='fade-up'>
        <div className="my-3 text-light">.</div>

          <h6 className="text-secondary text-uppercase"  data-aos='fade-up'>{t('servtitle')}</h6>
          <h1 className="mb-5" data-aos='fade-up'>{t('servqote')}</h1>
          <p className='w-75 mx-auto'>{t('servcont')} <br /> 
          <span className='text-danger'>{t('tip')}</span></p>
        </div>
        <div className="row g-4">
          <div className="col-md-6 col-lg-4 wow " data-aos='fade-up-left'>
            <div className="service-item p-4">
              <div className="overflow-hidden mb-4">
                <img className="img-fluid" src={serv1} alt="" />
              </div>
              <h4 className="mb-1">{t('servname1')}</h4>
              <ul> 
                <li> {t('fri1')}.</li>
                <li> {t('fri2')}</li>
                <li> {t('fri3')}.</li>
                </ul>
              <Link className="btn-slide mt-2" to="/freight"><i className="fa fa-arrow-right"></i><span>{t('btn1')}</span></Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 wow " data-aos='fade-up'>
            <div className="service-item p-4">
              <div className="overflow-hidden mb-4">
                <img className="img-fluid" src={serv2} alt="" />
              </div>
              <h4 className="mb-3"> {t('servname2')}</h4>
              <p>{t('para1')}....</p>
              <Link className="btn-slide mt-2" to='/custom'><i className="fa fa-arrow-right"></i><span>{t('btn1')}</span></Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 wow " data-aos='fade-up-left'>
            <div className="service-item p-4">
              <div className="overflow-hidden mb-4">
                <img className="img-fluid" src={serv6} alt="" />
              </div>
              <h4 className="mb-3">{t('servname3')} </h4>
              <p>{t('para2')}.... </p>
              <Link className="btn-slide mt-2" to="/insurance"><i className="fa fa-arrow-right"></i><span>{t('btn1')}</span></Link>
            </div>
          </div>
       
        </div>
      </div>
    </div>
  </div>
}
