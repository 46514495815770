import React, { useEffect } from 'react'
import './Home.css'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useTranslation } from 'react-i18next';
export default function Facts() {
    const { t, i18n } = useTranslation();

    
      useEffect(() => {
    Aos.init({
      duration: 2000
    })

  }, [])
  return <>
  <div className="container-xxl py-5">
      <div className="container py-5">
          <div className="row g-5">
              <div className="col-lg-6 wow " data-aos='fade-up'>
                  <h3 className="text-danger text-uppercase mb-3">{t('facts')}</h3>
                  <h1 className="mb-5">{t('factstit')}</h1>
                  <p className="mb-5">{t('factstip')}</p>
                      <h5>{t('factscall')}</h5>
                  <div className="d-flex align-items-center">
                      <i className="fa fa-headphones fa-2x flex-shrink-0 lonn p-3 text-white"></i> 
                      <div className="ps-4">
                         
                          <h3 className="text-danger m-0" dir='ltr'> +2 034238500</h3>
                          <h3 className="text-danger m-0" dir='ltr'> +2 01070757552 </h3>
                      </div>
                  </div>
              </div>
              <div className="col-lg-6">
                  <div className="row g-4 align-items-center">
                      <div className="col-sm-6">
                          <div className="lonn p-4 mb-4 wow " data-aos='fade-right'>
                              <i className="fa fa-users fa-2x text-white mb-3"></i>
                              <h2 className="text-white mb-2" data-toggle="counter-up">834</h2>
                              <p className="text-white mb-0">{t('clint')}</p>
                          </div>
                          <div className="lon p-4 wow fadeIn" data-aos='fade-up'>
                              <i className="fa fa-ship fa-2x text-white mb-3"></i>
                              <h2 className="text-white mb-2" data-toggle="counter-up">900</h2>
                              <p className="text-white mb-0">{t('clint2')}</p>
                          </div>
                      </div>
                      <div className="col-sm-6">
                          <div className="bg-success p-4 wow fadeIn" data-aos='fade-left'>
                              <i className="fa fa-star fa-2x text-white mb-3"></i>
                              <h2 className="text-white mb-2" data-toggle="counter-up">560</h2>
                              <p className="text-white mb-0">{t('clint3')}</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  </>
}
