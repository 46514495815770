import axios from "axios";
import React, { useEffect, useState } from "react";
import {toast} from "react-hot-toast";
   import Aos from 'aos'
    import 'aos/dist/aos.css'
import { useTranslation } from "react-i18next";

export default function Contact() {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
  
          useEffect (() => {
        Aos.init({
          duration: 2000
        })
    
      }, [])

    const [buttonText, setButtonText] = useState('Send');
    const [formInitialDetails, setFormInitialDetails] = useState({
        full_Name: "",
        email: "",
        phone: 0,
        service: "type of service",
        loadingPort: "",
        unloadingPort: "",
        weight: "",
        dimensions: "",
        shiping: "type of shipping",
        quantity: 0,
        transport: "mode of transport",
        commodity: "",
        message: "",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setButtonText("Sending...");
        await axios.post("https://good-ruby-termite-sari.cyclic.app/mail", formInitialDetails, {
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            }
        }).then(() => {
            console.log('d5l');
            setButtonText("Send");
            toast.success("Message sent successfully", { duration: 2000, className: " text-success" });

        }).catch(() => {
            setButtonText("Send");
            toast.error('Something went wrong', { duration: 2000, className: "bg-black text-white" });

        })
    };

    function getData(e) {
        const myData = { ...formInitialDetails };
        myData[e.target.name] = e.target.value;
        setFormInitialDetails(myData);
    }

    return  <div dir={isArabic?'rtl':''}>
            <div className="container-xxl py-5">
                <div className="text-center my-5">
                    <div className="my-3 text-light">.</div>

                    <h2 className="text-danger">{t('contact')}</h2>
                    <p>
                    {t('fill')}
                    </p>
                </div>
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-5 wow " data-aos='fade-up'>
                            <h6 className="text-secondary text-uppercase mb-3">
                            {t('btn2')}
                            </h6>
                            <h1 className="mb-5 text-danger">{t('req')}</h1>
                            <p className="mb-5">
                            {t('not')}
                            </p>
                            <div>
                                <h4 className="text-danger mb-4" data-aos='fade-up'>{t('add')}</h4>
                                <p className="mb-2">
                                    <i className="fa fa-map-marker-alt mx-3 text-danger"></i>
                                    {t('address')}
                                </p>
                                <p className="mb-2">
                                    <i className="fa fa-phone-alt mx-3 text-danger"></i> 
                                    00201070757552 
                                </p>
                                <p className="mb-2">
                                    <i className="fa fa-phone-alt mx-3 text-danger"></i> 
                                   002034238500
                                </p>
                                <p className="mb-2">
                                    <i className="fa fa-envelope mx-3 text-danger"></i>
                                    info@n-c-logistics.com
                                </p>
                                <div className="d-flex pt-2">
                                  
                                    <a className="btn btn-outline-primary  btn-social m-1" target="_blank" href="https://www.facebook.com/profile.php?id=61552945193682&mibextid=ZbWKwL" >
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                    <a className="btn btn-outline-primary  btn-social m-1" target="_blank" href="https://www.instagram.com/n.clogistics?igsh=M2hrYWxkc3g5ZWc3" >
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                  
                                    <a className="btn btn-outline-primary  btn-social m-1" target="_blank" href="https://www.linkedin.com/company/n-c-logistics/">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div
                                className="bg-light text-center p-5 wow "
                                data-aos='fade-up'
                            >
                                <form onSubmit={handleSubmit}>
                                    <div className="row g-3">
                                        <div className="col-12 col-sm-6">
                                            <input
                                                onChange={getData}
                                                name="full_Name"
                                                type="text"
                                                className="form-control border-0"
                                                placeholder={t('name')}
                                                style={{ height: "55px" }}
                                                required
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <input
                                                onChange={getData}  name="email"
                                                type="email"
                                                className="form-control border-0"
                                                placeholder={t('email')}
                                                style={{ height: "55px" }}
                                                required
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <input
                                                onChange={getData} name="phone"
                                                type="number"
                                                className="form-control border-0"
                                                placeholder={t('phone')}
                                                style={{ height: "55px" }}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <select
                                                onChange={getData}  name="service"
                                                className="form-select border-0"
                                                style={{ height: "55px" }}
                                            >
                                                <option value="">{t('service')}</option>
                                                <option value="International freight forwarding">{t('servname1')}</option>
                                                <option value="Custom clearance">
                                                {t('servname2')}
                                                </option>
                                                <option value="Insurance Services">
                                                {t('servname3')}
                                                </option>
                                             
                                            </select>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <input
                                                onChange={getData}
                                                name="loadingPort"
                                                type="text"
                                                className="form-control border-0"
                                                placeholder={t('loading')}
                                                style={{ height: "55px" }}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <input
                                                onChange={getData}
                                                name="unloadingPort"
                                                type="text"
                                                className="form-control border-0"
                                                placeholder={t('unloading')}
                                                style={{ height: "55px" }}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <input
                                                onChange={getData}
                                                name="weight"
                                                type="text"
                                                className="form-control border-0"
                                                placeholder={t('weight')}
                                                style={{ height: "55px" }}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <input
                                                onChange={getData}
                                                name="dimensions"
                                                type="text"
                                                className="form-control border-0"
                                                placeholder={t('dimensions')}
                                                style={{ height: "55px" }}
                                            />
                                        </div>


                                        <div className="col-12 col-sm-6">
                                            <input
                                                onChange={getData}
                                                name="quantity"
                                                type="number"
                                                className="form-control border-0"
                                                placeholder={t('quantity')}
                                                style={{ height: "55px" }}
                                            />
                                        </div>




                                        <div className="col-12 col-sm-6">
                                            <select
                                                onChange={getData}
                                                name="shiping"
                                                className="form-select border-0"
                                                style={{ height: "55px" }}
                                            >
                                                <option value="">{t('shipping')}</option>
                                                <option value="container">{t('container')}</option>
                                                <option value="Broke bulk">{t('Broke')}</option>
                                                <option value="liquid bulk">{t('liquid')}</option>
                                                <option value="RORO">{t('RORO')}</option>
                                                <option value="Other">{t('Other')}</option>
                                            </select>
                                        </div>


                                        <div className="col-12 col-sm-6">
                                            <select
                                                onChange={getData}
                                                name="transport"
                                                className="form-select border-0"
                                                style={{ height: "55px" }}
                                                
                                            >
                                                <option value=""> {t('transport')}</option>
                                                <option value="Air">{t('air')} </option>
                                                <option value=" Sea FCL"> {t('SeaFCL')}</option>
                                                <option value="Sea LCL">{t('SeaLCL')}</option>
                                                <option value="ROAD"> {t('road')}</option>
                                            </select>
                                        </div>


                                        <div className="col-12 col-sm-6">
                                            <input
                                                onChange={getData}
                                                name="commodity"
                                                type="text"
                                                className="form-control border-0"
                                                placeholder={t('Commodity')}
                                                style={{ height: "55px" }}
                                            />
                                        </div>

                                        <div className="col-12">
                                            <textarea
                                                onChange={getData}  name="message"
                                                className="form-control border-0"
                                                placeholder={t('message')}
                                            ></textarea>
                                        </div>
                                        <div className="col-12">
                                            <button
                                                className="btn btn-danger w-100 py-3"
                                                type="submit"
                                            >
                                                {buttonText}
                                            </button>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ;
}

