import React from 'react'
import frigh from '../../img/JetLogistics-etusivu-Hero-image-1920x1067.jpg'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
export default function Freight() {
    const { t, i18n } = useTranslation();
 const isArabic = i18n.language === 'ar';
    return <div dir={isArabic?'rtl':''}>
        <div className="container-xxl py-5">
            <div className=" my-5">
            <div className="my-5 text-light">.</div>

                <h2 className='text-danger h1 my-5'>{t('servname1')}</h2>
                <img src={frigh} alt="" className='w-50' />

            </div>
            <div className="container py-5">
                <div className='mb-5'>
                    <h2 className='text-danger'>{t('fri1')}</h2>
                    <h5 className='text-muted'>{t('air0')}</h5>
                </div>
                <ul>
                    <li className="my-2">
                        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>{t('air1')} </h5>
                    </li>
                    <li className="my-2">
                        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>{t('air2')} </h5>
                    </li>
                    <li className="my-2">
                        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>{t('air3')}</h5>
                    </li>
                    <li className="my-2">
                        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>{t('air4')}</h5>
                    </li>
                    <li className="my-2">
                        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>{t('air5')}</h5>
                    </li>


                </ul>
                <div className='my-5'>
                    <h2 className='text-danger'>{t('fri2')}</h2>
                    <h5 className='text-muted'>
                    {t('sea0')}
                    </h5>
                </div>
                <ul>

                    <li className="my-2">
                        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>   {t('sea1')} :
                            <Link to='/more' className='text-decoration-underline  text-success h6'  > see details</Link></h5>
                    </li>
                    <li className="my-2">
                        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>   {t('sea2')}</h5>
                    </li>
                    <li className="my-2">
                        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>   {t('sea3')}</h5>
                    </li>
                    <li className="my-2">
                        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>   {t('sea4')} </h5>
                    </li>
                    <li className="my-2">
                        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>   {t('sea5')}</h5>
                    </li>


                </ul>
                <div className='my-5'>
                    <h2 className='text-danger'>{t('fri3')}</h2>
                    <h5 className='text-muted'> 
                      {t('land0')}
                    </h5>
                </div>
                <ul>

                    <li className="my-2">
                        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>  
                        {t('land1')}
                         </h5>
                    </li>
                    <li className="my-2">
                        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>{t('land2')}</h5>
                    </li>
                    <li className="my-2">
                        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>{t('land3')}</h5>
                    </li>
                    <li className="my-2">
                        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>{t('land4')}</h5>
                    </li>                 
                </ul>
                <hr />
                <hr />
                <Link to="/contact" className="btn btn-danger py-3 px-5">{t('contact')}</Link>

            </div>
        </div>

    </div>
}
