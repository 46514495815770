import React, { useEffect } from 'react'
import './Home.css'
import cars1 from '../../img/carousel-1.jpg'
import cars2 from '../../images/carss.jpg'
import cars3 from '../../img/logistics-and-transport-ERP.jpg'
import who from '../../images/WhatsApp Image 2023-10-25 at 15.50.40_7a51996f.jpg'

import Offers from './Offers.jsx'
import Facts from './Facts.jsx'
import Contact from '../Contact/Contact.jsx'
import Member from './Member.jsx';
import Map from './Map.jsx'
import Services from '../Services/Services.jsx'
import { Link } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useTranslation } from 'react-i18next'


export default function Home() {
const { t, i18n } = useTranslation();
 const isArabic = i18n.language === 'ar';
useEffect(() => {
  Aos.init({
    duration:2000
  })
  
}, [])
  return <div dir={isArabic?'rtl':''}>
    <div id="carouselExampleCaptions" className="carousel slide  " >
      <div className="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
      </div>
      <div className="carousel-inner">
      <div className="my-3 text-light">.</div>
        <div className="carousel-item active">
        

          <img src={cars1} className="msa7a d-block  w-100" alt="..." />
          <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center p" style={{ background: `rgba(6, 3, 21, 0.5)` }}>
            <div className="container">
              <div className="row justify-content-center state">
                <div className="col-10 col-lg-8 p-5" data-aos='fade-up'>
                  <h3 className="text-danger text-uppercase mb-3 ">{t('title')} <p className='text-white mt-2 ' >{t('qote')}</p></h3>
                  <h1 className="display-3 text-white  mb-4">{t('desc')}</h1>
                  <h4 className="fs-5 fw-medium text-white mb-4 pb-2">{t('trans')}</h4>
                  <Link data-aos='fade-right' to="/freight" className="mb-5 btn btn-danger py-md-3 px-md-5 mx-2 ">{t('btn1')}</Link>
                  <Link data-aos='fade-left' to="/contact" className="mb-5 btn lon py-md-3 px-md-3 ">{t('btn2')}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <img src={cars2} className="msa7a d-block w-100" alt="..." />
          <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" style={{ background: `rgba(6, 3, 21, 0.5)` }}>
            <div className="container">
              <div className="row justify-content-center state">
                <div className="col-10 col-lg-8 p-5 " data-aos='fade-up'  >
                  <h3 className="text-danger text-uppercase mb-3 ">{t('title')} <p className='text-white  mt-2 ' >{t('qote')}</p></h3>
                  <h1 className="display-3 text-white  mb-4">{t('desc')}</h1>
                  <h4 className="fs-5 fw-medium text-white mb-4 pb-2">{t('trans2')}</h4>
                  <Link data-aos='fade-right'  to="/freight" className="mb-5 btn btn-danger py-md-3 px-md-5 mx-2 ">{t('btn1')}</Link>
                  <Link data-aos='fade-left'  to="/contact" className="mb-5 btn lon py-md-3 px-md-5 ">{t('btn2')}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <img src={cars3} className="msa7a d-block w-100" alt="..." />
          <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" style={{ background: `rgba(6, 3, 21, 0.5)` }}>
            <div className="container">
              <div className="row justify-content-center state">
                <div className="col-10 col-lg-8 p-5" data-aos='fade-up'>
                  <h3 className="text-danger text-uppercase mb-3 ">{t('title')} <p className='text-white  mt-2'>{t('qote')}</p></h3>
                  <h1 className="display-3 text-white  mb-4">{t('desc')}</h1>
                  <h4 className="fs-5 fw-medium text-white mb-4 pb-2">{t('trans3')} </h4>
                  <Link data-aos='fade-right' to="/freight" className="mb-5 btn btn-danger py-md-3 px-md-5 mx-2 ">{t('btn1')}</Link>
                  <Link data-aos='fade-left' to="/contact" className="mb-5 btn lon py-md-3 px-md-5 ">{t('btn2')}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>





    <div className='bgg py-5 mt-5'>
    <div className="container my-5">
      <div className="text-center my-5">
        <h2 className='text-danger ' data-aos='fade-up'>{t('title2')}</h2>
      <h5 data-aos='fade-up'>{t('qote2')}</h5>
        <div className="row my-5">
          <div className="col-md-6 pt-5">
            <p className=' h3 text-muted mt-5  'data-aos='fade-up'>{t('cont2')}</p>
          </div>
          <div className="col-md-6 p-5" data-aos='fade-up'>
            <img src={who} className='w-100' alt="" />
          </div>
        </div>
      </div>
    </div>
    </div>
  
    <Services />
    <Offers />
    <Facts />
    <Contact />
    <Map />
    <Member />


  </div>
}
