import React from 'react'
import cust from '../../images/shippingg.png'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

export default function Insurance() {
    const { t, i18n } = useTranslation();
 const isArabic = i18n.language === 'ar';
    return <div  dir={isArabic?'rtl':''}>
        <div className="container-xxl py-5">
            <div className=" my-5 ">
            <div className="my-5 text-light">.</div>

                <h2 className='text-danger h1 my-5'>{t('servname3')}</h2>
                <img src={cust} alt="" className='w-50' />
            </div>
            <div className="container py-5">
                <div className='mb-5'>
                    <h2 className='text-danger'>{t('servname3')}</h2>
                    <h5 className='text-muted'>{t('insure1')}</h5>
                </div>
                <ul className='mb-5'>
                    <li className="my-2">
                        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>{t('insure2')}</h5>
                    </li>
                    <li className="my-2">
                        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>{t('insure3')}</h5>
                    </li>
                    <li className="my-2">
                        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>{t('insure4')}</h5>
                    </li>
                    <li className="my-2">
                        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>{t('insure5')}</h5>
                    </li>
                </ul>
                <hr />
                <hr />
                <Link to="/contact" className="btn btn-danger py-3 px-5">{t('contact')}</Link>
            </div>
        </div>

    </div>
}
