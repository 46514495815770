import React from 'react'
import { useTranslation } from 'react-i18next';

export default function Quality() {

const { t, i18n } = useTranslation();

 const isArabic = i18n.language === 'ar';
    return <div dir={isArabic?'rtl':''}>
        <div className="container-xxl py-5">
            <div className="text-center my-5">
            <div className="my-5 text-light">.</div>
                <h2 className='text-danger'>{t('quality')}</h2>
            </div>
            <div className="container py-5">
            {t('quality1')}
            </div>
        </div>
    </div>
}
