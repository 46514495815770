import React from 'react'
import './Navbar.css'
import logo from '../../images/N.C.Logistics.png'
import {  useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function   Navbar() {

    const { t, i18n } = useTranslation();
 
  return <>
    <nav className="navbar navbar-expand-lg bg-white navbar-light shadow fixed-top p-0 mb-5"  >
        <Link to="/"className="navbar-brand mainn d-flex align-items-center px-4 px-lg-5">
        <img src={logo} alt="logo" className='logo' width={100} />
             </Link>
        <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto p-4 p-lg-0">
                <Link to="/" className="nav-item nav-link ">{t('home')}</Link>
                <Link to="about" className="nav-item nav-link">{t('about')}</Link>
                <Link to="services" className="nav-item nav-link">{t('services')}</Link>
                <div className="nav-item dropdown">
                    <Link  className="nav-link dropdown-toggle " data-bs-toggle="dropdown">{t('resources')}</Link>
                     <div className="dropdown-menu fade-up m-0">
                        <Link to="links"className="dropdown-item">{t('links')} </Link>
                      
                      
                    </div> 
                </div>
                <Link to="features" className="nav-item nav-link">{t('features')}</Link>
                <Link to="contact" className="nav-item nav-link">{t('contact')}</Link>
                {i18n.language==="ar"&&<button onClick={()=>{
                    i18n.changeLanguage('en')
                    
                }} className='btn btn-outline-danger h-50 my-auto me-2'>EN</button>}
                {i18n.language==="en"&&<button onClick={()=>{
                    i18n.changeLanguage('ar')
                }} className='btn btn-outline-danger h-50 my-auto me-2'>AR</button>}
                
            <h6 className="m-2 pe-lg-5  d-none d-lg-block p-2"><i className="fa fa-phone text-danger ">
                </i> +201070757552<br /><i className="fa fa-phone text-danger me-3"></i> +2 034238500</h6>
            </div>
        </div>
    </nav>

  </>
}





